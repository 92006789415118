import { lazy } from "react";

/// This file for all the imports in the project.

// Shared
export const ComponentOutlet = lazy(
  () => import("../components/shared/component-outlet")
);

//Traffic Light
export const TrafficLightsForm = lazy(
  () => import("../components/traffic-light/traffic-light-form")
);
export const TrafficLightDetails = lazy(
  () =>
    import(
      "../components/traffic-light/traffic-light-details/traffic-light-details"
    )
);

// Clients
export const ClientForm = lazy(
  () => import("../components/clients/clients-form")
);
export const ClientDetails = lazy(
  () => import("../components/clients/client-details/client-details")
);

// Buildings
export const BuildingDetails = lazy(
  () => import("../components/buildings/building-details/building-details")
);
export const BuildingsForm = lazy(
  () => import("../components/buildings/buildings-form")
);

// Contacts
export const ContactDetails = lazy(
  () => import("../components/contacts/contact-details/contact-details")
);
export const ContactsForm = lazy(
  () => import("../components/contacts/contacts-form")
);

// Plants
export const PlantsForm = lazy(
  () => import("../components/plants/plants-form")
);
export const PlantDetails = lazy(
  () => import("../components/plants/plant-details/plant-details")
);

//Tasks
export const TasksList = lazy(() => import("../components/tasks/tasks-list"));
export const TasksForm = lazy(() => import("../components/tasks/tasks-form"));
export const TaskDetails = lazy(
  () => import("../components/tasks/task-details/task-details")
);
export const ResolvedTasksList = lazy(
  () => import("../components/tasks/resolved-tasks-list")
);

export const TaskSettingsList = lazy(
  () => import("../components/task-settings/task-settings-list")
);

export const TaskSettingsDetails = lazy(
  () => import("../components/task-settings/task-settings-details")
);

//Sub Tasks
export const SubTasksList = lazy(
  () => import("../components/sub-tasks/sub-tasks-list")
);
export const SubTasksForm = lazy(
  () => import("../components/sub-tasks/sub-tasks-form")
);
export const SubTaskDetails = lazy(
  () => import("../components/sub-tasks/sub-tasks-details/sub-task-details")
);
export const ResolvedSubTasksList = lazy(
  () => import("../components/sub-tasks/history/resolved-sub-tasks-list")
);
export const OnsiteSubTasksList = lazy(
  () => import("../components/sub-tasks/history/onsite-sub-tasks-list")
);
export const ResolvedMicroSubTasksList = lazy(
  () => import("../components/sub-tasks/history/resolved-micro-sub-tasks-list")
);
export const LabSubTasksList = lazy(
  () => import("../components/sub-tasks/history/lab-sub-tasks-list")
);
export const YearlyPdfPage = lazy(
  () => import("../components/sub-tasks/history/yearly-pdf")
);

// Disturbances
export const DisturbancesList = lazy(
  () => import("../components/disturbances/disturbances-list")
);
export const DisturbanceDetails = lazy(
  () =>
    import("../components/disturbances/disturbance-details/disturbance-details")
);
export const CreateDisturbance = lazy(
  () => import("../components/disturbances/create-disturbance")
);
export const UpdateDisturbance = lazy(
  () => import("../components/disturbances/update-disturbance")
);
export const ResolvedDisturbanceList = lazy(
  () => import("../components/disturbances/resolved-disturbance-list")
);

// Products
export const ProductForm = lazy(
  () => import("../components/products/products-form")
);
export const ProductDetails = lazy(
  () => import("../components/products/product-details/product-details")
);

// Plant Products
export const PlantProductsList = lazy(
  () => import("../components/plant-products/plant-products-list")
);
export const PlantProductForm = lazy(
  () => import("../components/plant-products/plant-products-form")
);
export const PlantProductDetails = lazy(
  () =>
    import(
      "../components/plant-products/plant-product-details/plant-product-details"
    )
);

// Components

export const ComponentsList = lazy(
  () => import("../components/components/components-list")
);

export const ComponentsForm = lazy(
  () => import("../components/components/components-form")
);

export const ComponentDetails = lazy(
  () => import("../components/components/component-details/component-details")
);

// Roles
export const CreateRole = lazy(() => import("../components/roles/create-role"));
export const RoleDetails = lazy(
  () => import("../components/roles/role-details")
);
export const UpdateRole = lazy(() => import("../components/roles/update-role"));

// Users
export const UsersForm = lazy(() => import("../components/users/users-form"));
export const UserDetails = lazy(
  () => import("../components/users/user-details")
);
export const UsersCahngeForm = lazy(
  () => import("../components/users/users-change-form")
);
