import AddIcon from "@mui/icons-material/Add";
import Can, { AbilityContext } from "../../casl/Can";
import CustomButton from "../shared/custom-button";
import DataTable from "../shared/table";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadFileButton from "../shared/download-file-button";
import ExcelFileUpload from "../shared/excel-file-upload";
import Header from "../shared/header";
import LinkButton from "../shared/link-button";
import React, { useContext, useEffect, useState } from "react";
import SaveIcon from "@mui/icons-material/Save";
import useBreadcrumbStore from "../../stores/store-breadcrumbs";
import { Box } from "@mui/material";
import { columns } from "./clients.columns";
import { IconButton } from "@mui/material";
import { Outlet } from "react-router-dom";
import { showErrorAlert, showSuccessAlert } from "../shared/alerts";
import { transformFilterItemToFilterObject } from "../../utils/helper";
import { useFetchClients, useUploadClientsExcel } from "../../api/clients";
import {
  GridFilterItem,
  GridPaginationModel,
  GridSortItem,
} from "@mui/x-data-grid";
import { AxiosError } from "axios";
import { ErrorTypeModel } from "../../models/error/error.model";

const ClientsList = () => {
  const ability = useContext(AbilityContext);
  const [itemsPerPage, setItemsPerPage] = useState(12);
  const [currentPage, setCurrentPage] = useState(1);
  // Filter
  const [filters, setFilters] = useState({});
  // Sort
  const [sort, setSort] = useState({});

  const {
    data: clientsData,
    isLoading,
    isError,
    refetch: refetchClientsData,
  } = useFetchClients(currentPage, itemsPerPage, filters, sort);

  const clients = clientsData?.data; // Extract data from the 'data' property
  const clientsTotalItems = clientsData?.pager?.total;

  // reset the breadcrumb
  const resetBreadcrumb = useBreadcrumbStore((state) => state.resetBreadcrumb);

  const handlePageChange = (newPage: GridPaginationModel) => {
    // Fetch new data from the server based on the new page
    setCurrentPage(newPage.page + 1);
    setItemsPerPage(newPage.pageSize);
  };

  const [selectedFile, setSelectedFile] = useState<File | undefined>();
  const [isFileSelected, setIsFileSelected] = useState<boolean>(false);
  const [selectedFileName, setSelectedFileName] = useState<string | null>(null);
  const [fileKey, setFileKey] = useState(0);

  const uploadClientsExcelMutation = useUploadClientsExcel();

  const handleExcelUpload = (file: File) => {
    setSelectedFile(file);
    setSelectedFileName(file.name);
    setIsFileSelected(true);
  };

  const handleDeleteFile = () => {
    setIsFileSelected(false);
    setSelectedFile(undefined);
    setSelectedFileName(null);
    setFileKey((prevKey) => prevKey + 1); // Update the key to trigger re-render
  };

  const handleUploadExcel = async () => {
    try {
      if (selectedFile) {
        const formData = new FormData();
        formData.append("client_template", selectedFile);

        await uploadClientsExcelMutation.mutateAsync(formData);

        // Clear the selected file after successful upload
        handleDeleteFile();

        // Show success alert
        await showSuccessAlert(
          "Die Excel-Datei des Kunden wurde erfolgreich hochgeladen!"
        );

        // Refetch clients data after successful upload
        await refetchClientsData();
      }
    } catch (error) {
      const typedError = error as AxiosError<ErrorTypeModel>;

      if (typedError.response) {
        const { data } = typedError.response;
        showErrorAlert(data.message);
      } else if (typedError.request) {
        // Handle cases where the request was made, but no response was received
        showErrorAlert("No response received from the server.");
      } else {
        // Handle other errors
        showErrorAlert("An error occurred while processing the request.");
      }
    }
  };

  const handleFilterModelChange = (filterItem: GridFilterItem) => {
    const filterObject = transformFilterItemToFilterObject(filterItem);
    setFilters(filterObject);
  };

  const handleSortModelChange = (sortItem: GridSortItem) => {
    setSort(sortItem);
  };

  useEffect(() => {
    resetBreadcrumb();
  }, [resetBreadcrumb]);

  return (
    <>
      {!isError && (
        <Box
          sx={{
            marginTop: "60px",
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Header title="Kunden" />
            <Can I="create" a="Client">
              <LinkButton
                label="Klient erstellen"
                startIcon={<AddIcon />}
                to="/clients/create"
              />
            </Can>
          </Box>
          {(ability.can("view", "All Clients") || ability.can("view", "Assigned Clients")) &&
            <Box marginTop="20px">
              <DataTable
                parentPath="clients"
                data={clients ?? []}
                fieldsNames={columns}
                boxHeight={80}
                currentPage={currentPage - 1}
                totalItems={clientsTotalItems}
                itemsPerPage={itemsPerPage}
                onPageChange={handlePageChange}
                ssrFilter={true}
                ssrSort={true}
                isLoading={isLoading}
                onFilterChange={handleFilterModelChange}
                onSortChange={handleSortModelChange}
                showFilterPanel={true}
              />
            </Box>}
          <Box display="flex" marginTop="20px" gap="20px" alignItems="start">
            <Can I="import" a="Clients">
              <DownloadFileButton
                label="Kundenvorlage herunterladen"
                fileName={"Client_Template.xlsx"}
                filePath="/excel/Client_Template.xlsx"
              />
              <Box display="flex">
                <ExcelFileUpload
                  key={fileKey}
                  isFileSelected={isFileSelected}
                  onFileUpload={handleExcelUpload}
                />
                {isFileSelected && (
                  <Box
                    display="flex"
                    gap="10px"
                    pl="10px"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <small>Ausgewählte Datei: {selectedFileName}</small>
                    <IconButton aria-label="delete" onClick={handleDeleteFile}>
                      <DeleteIcon />
                    </IconButton>
                    <CustomButton
                      startIcon={<SaveIcon />}
                      label="Hochladen"
                      handleOnClick={handleUploadExcel}
                    />
                  </Box>
                )}
              </Box>
            </Can>
          </Box>

          <Outlet />
        </Box>
      )}
    </>
  );
};

export default ClientsList;
