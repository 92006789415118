import { RouterModel } from "../models/router.model";
import {
  BuildingDetails,
  ClientDetails,
  BuildingsForm,
  ClientForm,
  CreateRole,
  RoleDetails,
  UpdateRole,
  UserDetails,
  UsersForm,
  PlantDetails,
  PlantsForm,
  DisturbancesList,
  DisturbanceDetails,
  ResolvedDisturbanceList,
  CreateDisturbance,
  UpdateDisturbance,
  ComponentsList,
  ComponentsForm,
  ComponentDetails,
  ProductForm,
  ProductDetails,
  PlantProductForm,
  PlantProductsList,
  PlantProductDetails,
  TasksList,
  TasksForm,
  TaskDetails,
  ResolvedTasksList,
  SubTasksForm,
  SubTaskDetails,
  TrafficLightsForm,
  TrafficLightDetails,
  ResolvedSubTasksList,
  ResolvedMicroSubTasksList,
  OnsiteSubTasksList,
  ComponentOutlet,
  LabSubTasksList,
  TaskSettingsList,
  TaskSettingsDetails,
  UsersCahngeForm,
  ContactsForm,
  ContactDetails,
  YearlyPdfPage,
} from "./lazy-imports";

import ClientsList from "../components/clients/clients-list";
import ProductsList from "../components/products/products-list";
import RolesList from "../components/roles/roles-list";
import UsersList from "../components/users/users-list";
import AssignedTasksList from "../components/tasks/assigned-tasks-list";
import ComponentAudits from "../components/components/component-audits/component-audits";

export const ActivityRoute: RouterModel[] = [
  { path: "", title: "Assigned Sub Tasks", Component: AssignedTasksList },
  {
    path: "sub-tasks/:subTaskId",
    title: "Sub Task Details",
    Component: ComponentOutlet,
    children: [
      {
        path: "",
        title: "Task Details",
        Component: SubTaskDetails,
      },
    ],
  },
];

//Sub Tasks
export const SubTasksRoute: RouterModel[] = [
  {
    path: "create",
    title: "Create Sub Task",
    Component: SubTasksForm,
  },
  {
    path: ":subTaskId",
    title: "Sub Task Details",
    Component: ComponentOutlet,
    children: [
      {
        path: "",
        title: "Task Details",
        Component: SubTaskDetails,
      },
    ],
  },
];

//Tasks
export const TasksRoute: RouterModel[] = [
  {
    path: "",
    title: "Tasks",
    Component: TasksList,
  },
  {
    path: "create",
    title: "Create Task",
    Component: TasksForm,
  },
  {
    path: ":taskId",
    title: "Task Details",
    Component: ComponentOutlet,
    children: [
      {
        path: "",
        title: "Task Details",
        Component: TaskDetails,
      },
      {
        path: "sub-tasks",
        title: "Sub Tasks",
        Component: ComponentOutlet,
        children: SubTasksRoute,
      },
    ],
  },
];

//Task-settings
export const TaskSettingsRoute: RouterModel[] = [
  {
    path: "",
    title: "Task Settigs",
    Component: TaskSettingsList,
  },
  {
    path: ":taskId",
    title: "Task settings details",
    Component: ComponentOutlet,
    children: [
      {
        path: "",
        title: "Task Settigs",
        Component: TaskSettingsDetails,
      },
    ],
  },
];

// Components
export const ComponentsRoute: RouterModel[] = [
  {
    path: "",
    title: "Components",
    Component: ComponentsList,
  },
  {
    path: "create",
    title: "Create Component",
    Component: ComponentsForm,
  },
  {
    path: ":componentId",
    title: "Component Details",
    Component: ComponentOutlet,
    children: [
      {
        path: "",
        title: "Component Details",
        Component: ComponentDetails,
      },
      {
        path: "update",
        title: "Update Component",
        Component: ComponentsForm,
      },
      {
        path: "audits",
        title: "Component Audits",
        Component: ComponentAudits,
      },
    ],
  },
];

// Disturbances
export const DisturbancesRoute: RouterModel[] = [
  {
    path: "",
    title: "Disturbances",
    Component: DisturbancesList,
  },
  {
    path: "create",
    title: "Create Disturbance",
    Component: CreateDisturbance,
  },
  {
    path: "update/:disturbanceId",
    title: "Update Disturbance",
    Component: UpdateDisturbance,
  },
  {
    path: ":disturbanceId",
    title: "Disturbance Details",
    Component: ComponentOutlet,
    children: [
      {
        path: "",
        title: "Disturbance Details",
        Component: DisturbanceDetails,
      },
    ],
  },
];

// Products
export const ProductsRoute: RouterModel[] = [
  {
    path: "",
    title: "Products",
    Component: ProductsList,
  },
  {
    path: "create",
    title: "Create Product",
    Component: ProductForm,
  },
  {
    path: "update/:productId",
    title: "Update Product",
    Component: ProductForm,
  },
  {
    path: ":productId",
    title: "Product Details",
    Component: ComponentOutlet,
    children: [
      {
        path: "",
        title: "Product Details",
        Component: ProductDetails,
      },
    ],
  },
];

// Plant products
export const PlantProductsRoute: RouterModel[] = [
  {
    path: "",
    title: "Plant Products",
    Component: PlantProductsList,
  },
  {
    path: "create",
    title: "Plant Create Product",
    Component: PlantProductForm,
  },
  {
    path: "update/:productId",
    title: "Plant Update Product",
    Component: PlantProductForm,
  },
  {
    path: ":productId",
    title: "Plant Product Details",
    Component: ComponentOutlet,
    children: [
      {
        path: "",
        title: "Plant Product Details",
        Component: PlantProductDetails,
      },
    ],
  },
];

export const TrafficLightRoute: RouterModel[] = [
  { path: "", title: "Traffic Light", Component: TrafficLightDetails },
  {
    path: "create",
    title: "Create Traffic Light",
    Component: TrafficLightsForm,
  },
  {
    path: "update",
    title: "Update Traffic Light",
    Component: TrafficLightsForm,
  },
];

// History
export const HistoryPageRoute: RouterModel[] = [
  {
    path: "resolved-subtasks",
    title: "History - Resolved Subtasks",
    Component: ComponentOutlet,
    children: [
      {
        path: "",
        title: "History - Resolved Subtasks",
        Component: ResolvedSubTasksList,
      },
      {
        path: ":subTaskId",
        title: "Sub Task Details",
        Component: ComponentOutlet,
        children: [
          {
            path: "",
            title: "Task Details",
            Component: SubTaskDetails,
          },
        ],
      },
    ],
  },
  {
    path: "resolved-disturbances",
    title: "History - Resolved Disturbances",
    Component: ResolvedDisturbanceList,
  },
  {
    path: "onsite-subtasks-diagram",
    title: "History -On Site Subtasks Diagram",
    Component: OnsiteSubTasksList,
  },
  {
    path: "micro-subtasks-diagram",
    title: "History -Micro Subtasks Diagram",
    Component: ResolvedMicroSubTasksList,
  },
  {
    path: "lab-subtasks-diagram",
    title: "History -Lab Subtasks Diagram",
    Component: LabSubTasksList,
  },
  {
    path: "yearly-pdf-report",
    title: "History - Yearly Pdf Report ",
    Component: YearlyPdfPage,
  },
];
// Plants
export const PlantsRoute: RouterModel[] = [
  {
    path: ":plantId",
    title: "Plant Details",
    Component: ComponentOutlet,
    children: [
      {
        path: "",
        title: "Plant Details",
        Component: PlantDetails,
      },
      {
        path: "disturbances",
        title: "Disturbances",
        Component: ComponentOutlet,
        children: DisturbancesRoute,
      },
      {
        path: "components",
        title: "Components",
        Component: ComponentOutlet,
        children: ComponentsRoute,
      },
      {
        path: "traffic-light",
        title: "Traffic Light",
        Component: ComponentOutlet,
        children: TrafficLightRoute,
      },
      {
        path: "history",
        title: "History",
        Component: ComponentOutlet,
        children: HistoryPageRoute,
      },
      {
        path: "plant-products",
        title: "Plant Products",
        Component: ComponentOutlet,
        children: PlantProductsRoute,
      },
    ],
  },
  {
    path: "create",
    title: "Create Plant",
    Component: PlantsForm,
  },
  {
    path: "update/:plantId",
    title: "Update Plant",
    Component: PlantsForm,
  },
];

// Building
export const BuildingRoute: RouterModel[] = [
  {
    path: "create",
    title: "Create Building",
    Component: BuildingsForm,
  },
  {
    path: "update/:buildingId",
    title: "Update Building",
    Component: BuildingsForm,
  },
  {
    path: ":buildingId",
    title: "Building Details",
    Component: ComponentOutlet,
    children: [
      {
        path: "",
        title: "Building Details",
        Component: BuildingDetails,
      },
      {
        path: "tasks",
        title: "Tasks",
        Component: ComponentOutlet,
        children: TasksRoute,
      },
      {
        path: "task-settings",
        title: "Tasks",
        Component: ComponentOutlet,
        children: TaskSettingsRoute,
      },
      {
        path: "history",
        title: "History",
        Component: ComponentOutlet,
        children: [
          {
            path: "",
            title: "History",
            Component: ResolvedTasksList,
          },
          {
            path: "tasks/:taskId",
            title: "Task Details",
            Component: ComponentOutlet,
            children: [
              {
                path: "",
                title: "Task Details",
                Component: TaskDetails,
              },
              {
                path: "sub-tasks",
                title: "Sub Tasks",
                Component: ComponentOutlet,
                children: SubTasksRoute,
              },
            ],
          },
        ],
      },
      {
        path: "plants",
        title: "Plants",
        Component: ComponentOutlet,
        children: PlantsRoute,
      },
    ],
  },
];

// Contacts
export const ContactRoute: RouterModel[] = [
  {
    path: "create",
    title: "Create Contact",
    Component: ContactsForm,
  },
  {
    path: "update/:contactId",
    title: "Update Contact",
    Component: ContactsForm,
  },
  {
    path: ":contactId",
    title: "Contact Details",
    Component: ComponentOutlet,
    children: [
      {
        path: "",
        title: "Contact Details",
        Component: ContactDetails,
      },
    ],
  },
];

// Clients
export const ClientsRoute: RouterModel[] = [
  {
    path: "",
    title: "Clients",
    Component: ClientsList,
  },
  {
    path: "create",
    title: "Create Client",
    Component: ClientForm,
  },
  {
    path: "update/:clientId",
    title: "Update Client",
    Component: ClientForm,
  },
  {
    path: ":clientId",
    title: "Client Details",
    Component: ComponentOutlet,
    children: [
      {
        path: "",
        title: "Client Details",
        Component: ClientDetails,
      },
      {
        path: "buildings",
        title: "Buildings",
        Component: ComponentOutlet,
        children: BuildingRoute,
      },
      {
        path: "contacts",
        title: "Contacts",
        Component: ComponentOutlet,
        children: ContactRoute,
      },
    ],
  },
];

// Roles
export const RolesRoute: RouterModel[] = [
  {
    path: "",
    title: "Roles",
    Component: RolesList,
  },
  {
    path: ":id",
    title: "Role Details",
    Component: RoleDetails,
  },
  {
    path: "create",
    title: "Create Role",
    Component: CreateRole,
  },
  {
    path: "update/:id",
    title: "Update Role",
    Component: UpdateRole,
  },
];

// Users
export const UsersRoute: RouterModel[] = [
  {
    path: "",
    title: "Users",
    Component: UsersList,
  },
  {
    path: "create",
    title: "Create User",
    Component: UsersForm,
  },
  {
    path: "update/:id",
    title: "Update User",
    Component: UsersForm,
  },
  {
    path: "change/:id",
    title: "Update User",
    Component: UsersCahngeForm,
  },
  {
    path: ":id",
    title: "User Details",
    Component: UserDetails,
  },
];
