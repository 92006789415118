import { GridColDef, GridFilterModel } from "@mui/x-data-grid";
import { useMemo, useState } from "react";
import {
  useFetchCurrentUserTasks,
  useFetchDisturbancesClient,
  useFetchUpComingUserTasks,
} from "../api/components";
import { Subjects } from "../casl/ability";
import { formatDateToShortDate, transformFilterItemToFilterObject } from "../utils/helper";

interface IPager {
  page: number;
  pageSize: number;
}

interface FormattedTask {
  task_name: string;
  status: string;
  assignedUser: string;
  due_date: string | null;
  [key: string]: any;
}

interface TableData {
  rows: any;
  columns: any;
  paginationModel: any;
  sortModel: any;
  filterModel: any;
  isLoading: any;
  title: string;
  permission: Subjects;
  parentPath?: string;
  customLink?: string;
}

export const useDashboardTables = (): TableData[] => {
  const columns: GridColDef[] = [
    {
      field: "client_name",
      headerName: "Kunde",
      flex: 1,
    },
    {
      field: "created_by",
      headerName: "Aufgabenersteller",
      flex: 1,
    },
    {
      field: "sub_task_type",
      headerName: "Aufgabenart",
      flex: 1,
    },
    {
      field: "due_date",
      headerName: "Enddatum",
      flex: 1,
    },
  ];

  const columns_others: GridColDef[] = [
    {
      field: "client_name",
      headerName: "Kunde",
      flex: 1,
    },
    {
      field: "assigned_to",
      headerName: "Arbeiter",
      flex: 1,
    },
    {
      field: "sub_task_type",
      headerName: "Aufgabenart",
      flex: 1,
    },
    {
      field: "due_date",
      headerName: "Enddatum",
      flex: 1,
    },
  ];

  const clientDisturbancesColumns: GridColDef[] = [
    {
      field: "building_name",
      headerName: "Gebäude",
      flex: 1,
    },
    {
      field: "plant_name",
      headerName: "Anlage",
      flex: 1,
    },

    {
      field: "when",
      headerName: "Aufgetreten am",
      flex: 1,
    },

  ];

  const [selfCurrentPagination, setSelfCurrentPagination] = useState<IPager>({
    page: 1,
    pageSize: 10,
  });

  const [otherCurrentPagination, setOtherCurrentPagination] = useState<IPager>({
    page: 1,
    pageSize: 10,
  });

  const [selfUpComingPagination, setSelfUpComingPagination] = useState<IPager>({
    page: 1,
    pageSize: 10,
  });

  const [otherUpComingPagination, setOtherUpComingPagination] =
    useState<IPager>({
      page: 1,
      pageSize: 10,
    });

  const [clientDisturbancesPagination, setClientDisturbancesPagination] =
    useState<IPager>({
      page: 1,
      pageSize: 10,
    });

  const [selfCurrentFilter, setSelfCurrentFilter] = useState<GridFilterModel>();

  const [otherCurrentFilter, setOtherCurrentFilter] =
    useState<GridFilterModel>();

  const [selfCurrentSort, setSelfCurrentSort] = useState<any>();

  const [otherCurrentSort, setOtherCurrentSort] = useState<any>();

  const [selfUpComingFilter, setSelfUpComingFilter] =
    useState<GridFilterModel>();

  const [otherUpComingFilter, setOtherUpComingFilter] =
    useState<GridFilterModel>();

  const [selfUpComingSort, setSelfUpComingSort] = useState<any>();

  const [otherUpComingSort, setOtherUpComingSort] = useState<any>();

  const [clientDisturbancesFilter, setClientDisturbancesFilter] =
    useState<GridFilterModel>();

  const [clientDisturbancesSort, setClientDisturbancesSort] = useState<any>();

  const { data: selfCurrentTasksData, isLoading: selfCurrentTasksLoading } =
    useFetchCurrentUserTasks({
      user_type: "self",
      itemsPerPage: selfCurrentPagination.pageSize,
      page: selfCurrentPagination.page,
      ...transformFilterItemToFilterObject(
        selfCurrentFilter?.items?.[0] || { field: "", value: "", operator: "" }
      ),
      ...{
        sortField: selfCurrentSort?.field,
        sortDirection: selfCurrentSort?.sort,
      },
    });
  const { data: otherCurrentTasksData, isLoading: otherCurrentTasksLoading } =
    useFetchCurrentUserTasks({
      user_type: "other",
      itemsPerPage: otherCurrentPagination.pageSize,
      page: otherCurrentPagination.page,
      ...transformFilterItemToFilterObject(
        otherCurrentFilter?.items?.[0] || { field: "", value: "", operator: "" }
      ),
      ...{
        sortField: otherCurrentSort?.field,
        sortDirection: otherCurrentSort?.sort,
      },
    });

  const { data: selfUpComingTasksData, isLoading: selfUpComingTasksLoading } =
    useFetchUpComingUserTasks({
      user_type: "self",
      itemsPerPage: selfUpComingPagination.pageSize,
      page: selfUpComingPagination.page,
      ...transformFilterItemToFilterObject(
        selfUpComingFilter?.items?.[0] || { field: "", value: "", operator: "" }
      ),
      ...{
        sortField: selfUpComingSort?.field,
        sortDirection: selfUpComingSort?.sort,
      },
    });
  const { data: otherUpComingTasksData, isLoading: otherUpComingTasksLoading } =
    useFetchUpComingUserTasks({
      user_type: "other",
      itemsPerPage: otherUpComingPagination.pageSize,
      page: otherUpComingPagination.page,
      ...transformFilterItemToFilterObject(
        otherUpComingFilter?.items?.[0] || {
          field: "",
          value: "",
          operator: "",
        }
      ),
      ...{
        sortField: otherUpComingSort?.field,
        sortDirection: otherUpComingSort?.sort,
      },
    });

  const { data: clientDisturbancesData, isLoading: clientDisturbancesLoading } =
    useFetchDisturbancesClient({
      itemsPerPage: clientDisturbancesPagination.pageSize,
      page: clientDisturbancesPagination.page,
      ...transformFilterItemToFilterObject(
        clientDisturbancesFilter?.items?.[0] || {
          field: "",
          value: "",
          operator: "",
        }
      ),
      ...{
        sortField: clientDisturbancesSort?.field,
        sortDirection: clientDisturbancesSort?.sort,
      },
    });

  const formatTasks = (tasks: any[] | undefined): FormattedTask[] => {
    return (
      tasks?.map((task) => ({
        ...task,
        due_date: task.due_date? formatDateToShortDate(new Date(task.due_date)) : null 
      })) || []
    );
  };

  const selfCurrentTasks = useMemo(
    () => formatTasks(selfCurrentTasksData?.data),
    [selfCurrentTasksData]
  );
  const otherCurrentTasks = useMemo(
    () => formatTasks(otherCurrentTasksData?.data),
    [otherCurrentTasksData]
  );

  const selfUpComingTasks = useMemo(
    () => formatTasks(selfUpComingTasksData?.data),
    [selfUpComingTasksData]
  );
  const otherUpComingTasks = useMemo(
    () => formatTasks(otherUpComingTasksData?.data),
    [otherUpComingTasksData]
  );

  const clientDisturbancesTasks = useMemo(
    () => clientDisturbancesData?.data,
    [clientDisturbancesData]
  );

  return [
    {
      rows: selfCurrentTasks,
      columns,
      paginationModel: {
        data: {
          ...selfCurrentPagination,
          total: selfCurrentTasksData?.pager.total,
        },
        setPagination: setSelfCurrentPagination,
      },
      sortModel: {
        sort: selfCurrentSort,
        setSort: setSelfCurrentSort,
      },
      filterModel: {
        filter: selfCurrentFilter,
        setFilter: setSelfCurrentFilter,
      },
      isLoading: selfCurrentTasksLoading,
      title: "Meine fälligen Aufgaben",
      permission: "Self Current Activities",
      parentPath: "activity/sub-tasks",
    },
    {
      rows: otherCurrentTasks,
      columns: columns_others,
      paginationModel: {
        data: {
          ...otherCurrentPagination,
          total: otherCurrentTasksData?.pager.total,
        },
        setPagination: setOtherCurrentPagination,
      },
      sortModel: {
        sort: otherCurrentSort,
        setSort: setOtherCurrentSort,
      },
      filterModel: {
        filter: otherCurrentFilter,
        setFilter: setOtherCurrentFilter,
      },
      isLoading: otherCurrentTasksLoading,
      title: "Meine ausgeteilten Aufgaben",
      permission: "Other Current Activities",
      parentPath: "activity/sub-tasks",
    },
    {
      rows: selfUpComingTasks,
      columns,
      paginationModel: {
        data: {
          ...selfUpComingPagination,
          total: selfUpComingTasksData?.pager.total,
        },
        setPagination: setSelfUpComingPagination,
      },
      sortModel: {
        sort: selfUpComingSort,
        setSort: setSelfUpComingSort,
      },
      filterModel: {
        filter: selfUpComingFilter,
        setFilter: setSelfUpComingFilter,
      },
      isLoading: selfUpComingTasksLoading,
      title: "Meine Aufgaben in der Zukunft",
      permission: "Self Upcoming Activities",
      parentPath: "activity/sub-tasks",
    },
    {
      rows: otherUpComingTasks,
      columns: columns_others,
      paginationModel: {
        data: {
          ...otherUpComingPagination,
          total: otherUpComingTasksData?.pager.total,
        },
        setPagination: setOtherUpComingPagination,
      },
      sortModel: {
        sort: otherUpComingSort,
        setSort: setOtherUpComingSort,
      },
      filterModel: {
        filter: otherUpComingFilter,
        setFilter: setOtherUpComingFilter,
      },
      isLoading: otherUpComingTasksLoading,
      title: "Alle ausgeteilten Aufgaben",
      permission: "Other Upcoming Activities",
      parentPath: "activity/sub-tasks",
    },
    {
      rows: clientDisturbancesTasks?.data,
      columns: clientDisturbancesColumns,
      paginationModel: {
        data: {
          ...clientDisturbancesPagination,
          total: clientDisturbancesData?.pager?.total,
        },
        setPagination: setClientDisturbancesPagination,
      },
      sortModel: {
        sort: clientDisturbancesSort,
        setSort: setClientDisturbancesSort,
      },
      filterModel: {
        filter: clientDisturbancesFilter,
        setFilter: setClientDisturbancesFilter,
      },
      isLoading: clientDisturbancesLoading,
      title: "Anlagenstörungen",
      permission: "Disturbances Client",
      customLink:
        "/clients/{client_id}/buildings/{building_id}/plants/{plant_id}/disturbances/{disturbances_id}",
    },
  ];
};
