import React from "react";
import { NavigateFunction } from "react-router-dom";
import { Typography, Button, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

const ForbiddenPage = () => {
	const navigate: NavigateFunction = useNavigate();

	return (
		<>
			<Box
				display={"flex"}
				justifyContent={"center"}
				alignItems={"center"}
				flexDirection={"column"}
				width="100%"
				height="100vh"
			>
				<Typography
					variant="h1"
					className="error-head"
					fontSize="160px"
					fontWeight="700"
				>
					403
				</Typography>
				<Typography
					variant="h2"
					paddingBottom={1}
					fontWeight="700"
					lineHeight="1.1"
					marginBottom="0.5em"
				>
					Oops! Why you’re here?
				</Typography>
				<Typography
					variant="h6"
					lineHeight="1.1"
					marginBottom="1em"
					letterSpacing="1px"
				>
					It looks like you’re trying to access a page that you do not have
					permission to access.
				</Typography>
				<Button
					variant="contained"
					onClick={() => {
						navigate(-2);
					}}
					className="error-btn"
					sx={{
						margin: "1em 0em",
					}}
				>
					Back
				</Button>
			</Box>
		</>
	);
};

export default ForbiddenPage;
