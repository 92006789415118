import ClientsOutlet from "../components/clients/index";
import Dashboard from "../components/pages/dashboard-page";
import EmailVerificationPage from "../components/auth/email-verification";
import ForgotPasswordPage from "../components/auth/forgot-password";
import Layout from "../components/layout";
import LoginPage from "../components/auth/login-page";
import Messenger from "../components/chat/index";
import NotFound from "../components/errors/not-found";
import NotificationsList from "../components/notifications/index";
import Parameters from "../components/pages/parameters-page";
import ResetPasswordPage from "../components/auth/reset-password";
import SetPasswordPage from "../components/auth/set-password";
import Settings from "../components/pages/settings-page";
import Template from "../components/pages/template-page";
import UsersOutlet from "../components/users/index";
import ActivityPage from "../components/pages/activity-page";
import ImprintPage from "../components/pages/imprint-page";
import ComponentOutlet from "../components/shared/component-outlet";
import { RouterModel } from "../models/router.model";
import {
  ClientsRoute,
  ProductsRoute,
  RolesRoute,
  UsersRoute,
  ActivityRoute,
} from "./nested-routes";
import ACInternDownload from "../components/pages/ACIntern-downloads";
import KundenDownloads from "../components/pages/kunden-downloads";
import ForbiddenPage from "../components/errors/forbidden";

export const PUBLIC_ROUTES: RouterModel[] = [
  {
    path: "/imprint",
    title: "Imprint",
    Component: ImprintPage,
  },
  {
    path: "/forbidden",
    title: "Forbidden",
    Component: ForbiddenPage,
  },
  {
    path: "/login",
    title: "Login",
    Component: LoginPage,
  },
  {
    path: "*",
    title: "Not Found",
    Component: NotFound,
  },
  {
    path: "/forgot-password",
    title: "Forgot Password",
    Component: ForgotPasswordPage,
  },
  {
    path: "/set-password",
    title: "Set password",
    Component: SetPasswordPage,
  },
  {
    path: "/reset-password",
    title: "Reset password",
    Component: ResetPasswordPage,
  },
  {
    path: "/api/auth/email/verify/:id/:hash",
    title: "Email Verification",
    Component: EmailVerificationPage,
  },
  {
    path: "",
    Component: Layout,
    children: [
      {
        path: "dashboard",
        title: "Dashboard",
        Component: Dashboard,
      },
      {
        path: "messenger",
        title: "Messenger",
        Component: Messenger,
      },
      {
        path: "template",
        title: "Template",
        Component: Template,
      },
      {
        path: "parameters",
        title: "Parameters",
        Component: Parameters,
      },
      {
        path: "clients",
        title: "Clients",
        Component: ClientsOutlet,
        children: ClientsRoute,
      },
      {
        path: "products",
        title: "Products",
        Component: ComponentOutlet,
        children: ProductsRoute,
      },
      {
        path: "roles",
        title: "Roles",
        Component: ComponentOutlet,
        children: RolesRoute,
      },
      {
        path: "users",
        title: "Users",
        Component: UsersOutlet,
        children: UsersRoute,
      },
      {
        path: "notifications",
        title: "Notifications",
        Component: NotificationsList,
      },
      {
        path: "settings",
        title: "Settings",
        Component: Settings,
      },
      {
        path: "/impressum",
        title: "Imprint",
        Component: ImprintPage,
      },
      {
        path: "activity",
        title: "Activity",
        Component: ActivityPage,
        children: ActivityRoute,
      },
      {
        path: "acintern-download",
        title: "AC Intern",
        Component: ACInternDownload,
      },
      {
        path: "kunden-downloads",
        title: "Kunden",
        Component: KundenDownloads,
      },
    ],
  },
];
