import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
  useTheme,
  Divider,
  useMediaQuery,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import GetAppIcon from "@mui/icons-material/GetApp";
import { tokens } from "../layout/theme";
import { FileCategoryKunden } from "../../models/download-model";
import {
  brochureSection,
  coraconCategories,
  industriwasserCategories,
} from "../../data/download-lists";

const renderFileCategoryList = (
  categories: FileCategoryKunden[],
  theme: any,
  type: "nested" | "normal",
  color: string,
  secondaryColor: string
) => {
  return (
    <>
      {type === "nested" ? (
        categories.map((file, fileIndex) => (
          <Accordion
            disableGutters
            sx={{ backgroundColor: color }}
            key={fileIndex}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ fontSize: 16 }} />}
            >
              <Typography variant="h6">{file.germanTitle}</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ backgroundColor: secondaryColor }}>
              {/* Content of the accordion */}
            </AccordionDetails>
          </Accordion>
        ))
      ) : (
        <List>
          {categories.map((file, fileIndex) => (
            <React.Fragment key={fileIndex}>
              <a
                href={file.fileUrl}
                download
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <ListItem
                  sx={{
                    listStyle: "outside",
                    textDecoration: "none",
                    width: "fit-content",
                  }}
                >
                  <ListItemText
                    sx={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: { xs: "normal", sm: "nowrap" }, // Use responsive styling for text wrapping
                      wordBreak: { xs: "break-word", sm: "normal" },
                      maxWidth: { xs: "200px", sm: "none" }, // Restrict width on small screens
                      flex: "none",
                      color: theme.palette.mode === "dark" ? "white" : "black",
                    }}
                    primary={file.germanTitle}
                  />
                  <IconButton edge="end" aria-label="download">
                    <GetAppIcon />
                  </IconButton>
                </ListItem>
              </a>
              <Divider orientation="horizontal" variant="middle" />
            </React.Fragment>
          ))}
        </List>
      )}
    </>
  );
};

const renderAccordion = (
  title: string,
  categories: FileCategoryKunden[],
  theme: any,
  type: "nested" | "normal",
  color: string,
  secondaryColor: string
) => {
  return (
    <Accordion disableGutters sx={{ backgroundColor: color }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ fontSize: 16 }} />}>
        <Typography
          variant="h5"
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: { xs: "normal", sm: "nowrap" }, // Responsive text wrapping
            wordBreak: { xs: "break-word", sm: "normal" }, // Break long words on small screens
            maxWidth: { xs: "200px", sm: "none" }, // Restrict width on small screens
          }}
        >
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ backgroundColor: secondaryColor }}>
        {renderFileCategoryList(categories, theme, type, color, secondaryColor)}
      </AccordionDetails>
    </Accordion>
  );
};

const KundenDownloads: React.FC = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Paper elevation={5}>
      <Box
        display="flex"
        flexDirection="column"
        bgcolor={colors.primary[400]}
        borderRadius="5px"
        marginTop="20px"
      >
        <Box
          ml={{ md: "0px", xs: "0px" }}
          mt="20px"
          sx={{
            display: "flex",
            flexDirection: { md: "row", xs: "column" },
            minHeight: "93vh",
            justifyContent: { md: "space-around", xs: "flex-start" },
            gap: "30px",
            padding: "20px",
          }}
        >
          <Box sx={{ flex: { md: 0.5, xs: 0 } }}>
            <Typography variant="h4" gutterBottom>
              Industriewasser
            </Typography>
            {renderAccordion(
              "Ausschreibungstexte",
              industriwasserCategories,
              theme,
              "nested",
              colors.primary[400],
              colors.primary[500]
            )}
            {renderAccordion(
              "Broschüren",
              brochureSection,
              theme,
              "normal",
              colors.primary[400],
              colors.primary[500]
            )}
          </Box>
          <Box sx={{ flex: { md: 0.5, xs: 0 } }}>
            <Typography variant="h4" sx={{ mb: "6px" }}>
              Coracon
            </Typography>
            {renderAccordion(
              "Ausschreibungstexte",
              coraconCategories,
              theme,
              "nested",
              colors.primary[400],
              colors.primary[500]
            )}
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

export default KundenDownloads;
