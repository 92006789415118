import { ChatProps, IdentityProps, MessageProps } from "../utils/chat-types";
import { useQuery, useMutation, MutationFunction } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { ChatApiService } from "./chat.api.service";
import { extractOriginalId, mapDialogToChatProps } from "../utils/chat-helper";
import useSocketStore from "../stores/store-socket";

// Get self info
export const useFetchSelfInfo = () => {
  const queryKey = ["selfInfo"];

  const { refetch, ...queryResult } = useQuery<
    IdentityProps | undefined,
    AxiosError
  >(queryKey, async () => {
    const response = await ChatApiService.get("/self");
    if (response) {
      const userProps: IdentityProps = {
        id: response.pk,
        name: response.username,
        username: response.username,
        avatar: "",
        online: true,
      };
      return userProps;
    }
    // If the request fails, return undefined
    return undefined;
  });

  return { ...queryResult, refetch };
};

// Get list of dialogs
export const useFetchDialogsList = () => {
  const queryKey = ["dialogsList"];

  const { refetch, ...queryResult } = useQuery<any[] | undefined, AxiosError>(
    queryKey,
    async () => {
      // const response = await ChatApiService.get("/dialogs");
      // if (response.data) {
      //   const dialogsData = response.data as any[]; // Assuming the response is an array
      //   // Convert the dialogsData into ChatProps[]
      //   const chats = dialogsData.map(mapDialogToChatProps);
      //   return chats;
      // }
      // // If the request fails, return undefined
      // return undefined;
      return Promise.resolve([]);
    }
  );

  return { ...queryResult, refetch };
};

// Add new dialogs
export const useAddNewDialogs = () => {
  const {
    setSocketConnectionState,
    socketConnectionState,
    socketRefCurrentState,
  } = useSocketStore();

  const addNewDialogs: MutationFunction<ChatProps[], string> = async (
    username
  ) => {
    const response = await ChatApiService.get(`/dialogs?otherUser=${username}`);

    if (response.data) {
      const dialogsData = response.data as any[]; // Assuming the response is an array

      // Convert the dialogsData into ChatProps[]
      const chats: ChatProps[] = dialogsData.map((dialog) => {
        let lastMessage;
        if (dialog.last_message && dialog.last_message.file)
          lastMessage = dialog.last_message.file.name;
        else lastMessage = dialog.last_message?.text;
        const timestamp = new Date(
          dialog.last_message?.sent * 1000
        ).toLocaleString();
        const unread = dialog.unread_count;
        const lastseen = new Date(
          dialog.other_user_last_login
        ).toLocaleString();
        const sender: IdentityProps = {
          id: dialog.other_user_id,
          name: dialog.username,
          username: dialog.username,
          avatar: "", // Set the appropriate avatar value
          online: dialog.is_user2_online, // Set the appropriate online value
        };

        const chatId = `dialog_${dialog.id.toString()}`;

        return {
          id: chatId,
          sender: sender,
          unread: unread,
          lastseen: lastseen,
          lastMessage: lastMessage,
          timestamp: timestamp,
          type: "dialog",
        };
      });

      setSocketConnectionState(
        socketConnectionState,
        socketRefCurrentState,
        chats
      );
      return chats; // Return the converted chats
    }

    return [];
  };

  return useMutation(addNewDialogs);
};

// Get messages for a dialog
export const useFetchDialogMessages = (dialogId?: string, type?: string) => {
  const queryKey = ["dialogMessages", dialogId];

  const { refetch, ...queryResult } = useQuery<
    MessageProps[] | undefined,
    AxiosError
  >(queryKey, async () => {
    if (dialogId && type) {
      const originalId = extractOriginalId(dialogId);
      let messagesEndpoint = "";
      if (type == "dialog") messagesEndpoint = `/messages/${originalId}`;
      else if (type == "group")
        messagesEndpoint = `/group_messages/${originalId}`;
      const response = await ChatApiService.get(messagesEndpoint);
      if (response.data) {
        const responseData = response.data;
        const messagesData = responseData as any[]; // Assuming the response is an array
        const messages: MessageProps[] = messagesData.map((message) => {
          // Convert the message data into MessageProps
          return {
            id: message.id.toString(),
            content: message.text,
            timestamp: new Date(message.sent * 1000).toLocaleString(),
            read: message.read,
            sender: {
              id: message.sender,
              name: message.sender_username,
              username: message.sender_username,
              avatar: "",
              online: false,
            },
            attachment: message.file
              ? {
                  id: message.file.id,
                  fileName: message.file.name,
                  type: message.file.name.split(".").pop(),
                  size: Math.round(message.file.size / 1024) + "KB",
                  url: message.file.url,
                }
              : undefined,
          };
        });

        return messages;
      }
    }
    // If the request fails, return undefined
    return undefined;
  });

  return { ...queryResult, refetch };
};

// Upload a file
export const useUploadFile = () => {
  const uploadFile: MutationFunction<any, File> = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    const response = await ChatApiService.postFormData("/upload", formData);
    return response;
  };

  return useMutation(uploadFile);
};

// Create a group
export const useCreateGroup = () => {
  const createGroup: MutationFunction<
    any,
    { participantIds: string[]; groupName: string }
  > = async (data) => {
    const response = await ChatApiService.post("/create_group", data);
    return response;
  };

  return useMutation(createGroup);
};

// Edit a group
export const useEditGroup = () => {
  const editGroup: MutationFunction<
    any,
    { groupId: string; newParticipantIds: string[]; newGroupName: string }
  > = async (data) => {
    const response = await ChatApiService.post("/edit_group", data);
    return response;
  };

  return useMutation(editGroup);
};
