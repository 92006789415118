import React, { useState } from "react";
import { TextField, IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

type RenderTextFieldProps = {
  name: string;
  label: string;
  value: string;
  error: boolean;
  helperText: string;
  handleBlur: any;
  handleChange: any;
  type?: string;
  span?: string;
  disabled?: boolean;
  mt?: string;
  multiline?: boolean;
  rows?: number;
  required?: boolean;
};

const RenderTextField: React.FC<RenderTextFieldProps> = ({
  name,
  label,
  value,
  error,
  helperText,
  handleBlur,
  handleChange,
  type = "text",
  span = "2",
  disabled = false,
  mt = "0px",
  multiline = false,
  rows = 1,
  required = false,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const isPasswordField = type === "password";

  return (
    <TextField
      required={required}
      fullWidth
      variant="filled"
      type={isPasswordField && showPassword ? "text" : type}
      label={label}
      onBlur={handleBlur}
      onChange={handleChange}
      value={value}
      name={name}
      error={error}
      helperText={helperText}
      FormHelperTextProps={{
        style: {
          fontSize: "1rem",
        },
      }}
      inputProps={{
        style: {
          fontSize: "1.2rem",
        },
      }}
      sx={{
        gridColumn: `span ${span}`,
        marginTop: `${mt}`,
        paddingRight: `15px`,
      }}
      disabled={disabled}
      multiline={multiline}
      rows={rows}
      InputProps={{
        endAdornment: isPasswordField && (
          <InputAdornment position="end">
            <IconButton
              onClick={handleClickShowPassword}
              edge="end"
              aria-label="toggle password visibility"
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default RenderTextField;
