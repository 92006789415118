export enum TasksStatusEnum {
  Neu = 6,
  Aktiv = 7,
  Verzögert = 8,
  Gelöst = 9,
  Fertig = 14,
}

export enum TaskType {
  einmalig = 1,
  wiederkehrend = 2,
}

export enum TaskTypeName {
  Normal = "normal",
  Nachverfolgen = "follow_up",
}

export enum RepeatedType {
  Tag = 3,
  Woche = 4,
  Monat = 5,
}
